import { Link, createFileRoute } from '@tanstack/react-router';

import { BoardCollectionDataProps, ExpertProductType, ExpertProducts } from '@/_shared/constants';
import { BoardDialog } from '@/components/BoardDialog.tsx';
import FreeTrialEnding from '@/components/FreeTrialEnding.tsx';
import { Stack, Text } from '@/components/custom-components';
import CollectionCard from '@/components/custom-components/CollectionCard/index.tsx';
import { FeatureTabs } from '@/components/custom-components/FeatureTabs/index.tsx';
import { Loader } from '@/components/custom-components/Loader';
import { LockTagSmall } from '@/components/custom-components/LockTag/index.tsx';
import { ErrorDisplay } from '@/components/error.tsx';
import { Badge } from '@/components/shadcn/badge.tsx';
import { Button } from '@/components/shadcn/button.tsx';
import { trpc } from '@/utils/trpc.ts';
import { Plus, UserWaves } from '@mynaui/icons-react';
import { useEffect, useState } from 'react';
import { Expert } from '../../../../src/shared/airtable';

type SearchParams = {
  sideBarOpen?: boolean;
};

// const ExpertPackPlanID = "pln_expert-pack-cvuf01gf";

export const Route = createFileRoute('/feeds/collections/experts/')({
  component: All,
  validateSearch: (search: Record<string, unknown>): SearchParams => {
    const sideBarOpen = search?.sideBarOpen as boolean;

    return {
      sideBarOpen,
    };
  },
});

function All() {
  const [createBoardDialogOpen, setCreateBoardDialogOpen] =
    useState<boolean>(false);

  const [upgradeDialogOpen, setUpgradeDialogOpen] = useState<boolean>(false);

  const { data: permissionData, isLoading: isLoadingPermission } =
    trpc.permissions.useQuery(null, {});

  useEffect(() => {
    if (!isLoadingPermission && !permissionData?.userCanAccessEverything) {
      setUpgradeDialogOpen(true);
    }
  }, [permissionData?.userCanAccessEverything, isLoadingPermission]);

  return (
    <>
      {createBoardDialogOpen && (
        <BoardDialog
          open={createBoardDialogOpen}
          onOpenChange={() => setCreateBoardDialogOpen(false)}
        />
      )}
      {upgradeDialogOpen && permissionData && (
        <FreeTrialEnding
          open={upgradeDialogOpen}
          onOpenChange={() => setUpgradeDialogOpen(false)}
          permissionData={permissionData}
        />
      )}
      <Stack className="gap-3 lg:gap-6">
        <Stack className="gap-3 lg:gap-8">
          <FeatureTabs
            defaultOrderValue={'Random'}
            urlSegmentCountToCompare={2}
            tabItems={[
              {
                name: 'Curated',
                link: !(permissionData?.userCanAccessEverything ?? false)
                  ? '/feeds/collections/creativeos'
                  : '/feeds/collections',
              },
              {
                name: 'Live Trends',
                link: '/feeds/live-trends',
              },
            ]}
          />
          <Stack className="gap-3 lg:gap-6">
            <Stack className="gap-2">
              <div className="gap-2 flex items-center justify-between">
                <Text weight="semibold" size={'xxl'}>
                  Pro Curated. Pro Approved.
                </Text>
              </div>
              <Text
                weight="normal"
                size={'lg'}
                className="text-thememutedforeground hidden lg:flex"
              >
                Expertly chosen collections that raise the bar.
              </Text>
            </Stack>

            <div className={'flex justify-between gap-6 items-center'}>
              <div className="flex gap-2 items-center">
                {[
                  ...(permissionData?.userCanAccessEverything
                    ? [
                        {
                          name: 'All',
                          link: '/feeds/collections',
                          selected: false,
                        },
                      ]
                    : []),
                  {
                    name: 'by CreativeOS',
                    link: '/feeds/collections/creativeos',
                    icon: (
                      <img
                        alt={''}
                        src={'/bg_gradient.jpg'}
                        className={'rounded-full shrink-0 bg-[#A259FF] w-4 h-4'}
                      />
                    ),
                  },
                  {
                    name: 'Experts',
                    link: '/feeds/collections/experts',
                    icon: <UserWaves className="w-4 h-4" />,
                    isPremium: true,
                    selected: true,
                  },
                ].map((item) => (
                  <Link
                    to={item.link}
                    search={(d) => ({
                      ...d,
                      cacheBuster: Math.random(),
                      orderFilter: 'Random',
                      sideBarOpen: false,
                    })}
                    activeOptions={{
                      exact: true,
                      includeSearch: false,
                    }}
                    key={item.name}
                  >
                    <Badge
                      variant={item.selected ? 'default' : 'outline'}
                      className="gap-1 cursor-pointer items-center"
                    >
                      {item.icon}
                      {item.name}
                      {item.isPremium && (
                        <>
                          {!isLoadingPermission &&
                            !permissionData?.userCanAccessEverything && (
                              <div className="opacity-50">
                                <LockTagSmall />
                              </div>
                            )}
                        </>
                      )}
                    </Badge>
                  </Link>
                ))}
              </div>

              <div className="gap-2 items-center hidden md:flex">
                <Button
                  onClick={() => setCreateBoardDialogOpen(true)}
                  size={'sm'}
                  variant={'ghost'}
                >
                  Create Your Own <Plus className="w-5 h-5" />
                </Button>
                <Text
                  weight="normal"
                  size={'base'}
                  className="text-thememutedforeground"
                >
                  {(ExpertProducts ?? []).length}
                  {(ExpertProducts ?? []).length > 1
                    ? ' Collections'
                    : ' Collection'}
                </Text>
              </div>
            </div>
          </Stack>
        </Stack>
        {!isLoadingPermission &&
          permissionData?.userCanAccessEverything &&
          ExpertProducts && <ExpertsGridView collections={ExpertProducts} />}
      </Stack>
    </>
  );
}

const ExpertsGridView = (props: BoardCollectionDataProps) => {
  return (
    <div className={'grid lg:grid-cols-2 gap-5 mb-10'}>
      {props.collections && props.collections.length > 0
        ? props.collections.map((item) => (
            <ExpertCollectionItem key={item.title} data={item} />
          ))
        : props.collections.length === 0 && (
            <div
              className={
                'h-full w-full lg:col-span-2 flex justify-center items-center'
              }
            >
              <p>No collections created</p>
            </div>
          )}
    </div>
  );
};

export const ExpertCollectionItem = ({ data }: { data: ExpertProductType }) => {
  const {
    data: adTemplates,
    isLoading,
    error,
  } = trpc.posts.useQuery(
    {
      Expert: data.title as Expert,
      Tags: undefined,
      limit: 6,
      cursor: undefined,
      Ready: true,
      sortingOptions: undefined,
    },
    {
      refetchOnMount: false,
      refetchOnWindowFocus: false,
    },
  );

  if (error) {
    return <ErrorDisplay />;
  }

  if (isLoading) {
    return (
      <div className={'flex justify-center items-center w-full h-full m-auto'}>
        <Loader />
      </div>
    );
  }

  return (adTemplates?.TotalRecords ?? 0) < 1 ? (
    <></>
  ) : (
    <Link to={data.url}>
      <Stack className="gap-3">
        <CollectionCard
          videoUrls={[]}
          imageUrls={
            adTemplates?.ATRecords?.map(
              (record) =>
                record.imageUrl ||
                record['Example Image'][0].thumbnails.large.url,
            ) ?? []
          }
        />
        <div className="flex justify-between">
          <Stack className="gap-1 w-full">
            <h4
              className={`truncate font-semibold text-sm leading-5 text-themeforeground`}
            >
              {data.title}
            </h4>

            <div className={`flex gap-1 items-center`}>
              <img
                src={data.imageLink}
                alt={'CreativeOs'}
                className={`bg-[#BCBCBC] rounded-full w-6 h-6 object-contain`}
              />

              <Text
                weight={'normal'}
                size={'xs'}
                className={`text-thememutedforeground w-fit`}
              >
                {data.speciality} by {data.title}
              </Text>
              <Badge variant={'destructivePurple'}>Expert</Badge>
            </div>
          </Stack>
          <Text
            className="text-thememutedforeground w-fit text-nowrap"
            size={'xs'}
            weight={'normal'}
          >
            {adTemplates?.TotalRecords ?? 0}{' '}
            {(adTemplates?.TotalRecords ?? 0) > 1 ||
            (adTemplates?.TotalRecords ?? 0) == 0
              ? ' Items'
              : ' Item'}
          </Text>
        </div>
      </Stack>
    </Link>
  );
};
