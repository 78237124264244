import { Button, buttonVariants } from "@/components/shadcn/button.tsx";
import { Ad } from "@/components/templates/DiscoverGridView";
import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from "@/components/ui/tooltip.tsx";
import { useCopyToClipboard } from "@/hooks/useCopyToClipboard";
import showToastNotification from "@/hooks/useShowToast";
import { cn } from "@/lib/utils.ts";
import { Download } from "@mynaui/icons-react";
import { Link as LinkIcon } from "@mynaui/icons-react";
import { Link } from "@tanstack/react-router";
import { Ad as AdCreativeDetails } from "../../../../src/server/types";
import { ManuallySaveAdToBoard } from "./templates/AdCard";

export const AdInspirationDetailsMedia = ({
  adCreativeDetails,
  data,
  IsPublic,
  numberOfActiveAdsForBrand,
  saveOpen,
  setSaveOpen,
}: {
  adCreativeDetails?: AdCreativeDetails | null;
  data: Ad;

  numberOfActiveAdsForBrand:
    | {
        numberOfActiveAds: number;
      }
    | undefined;
  saveOpen: boolean;
  setSaveOpen: (x: boolean) => void;
  IsPublic: boolean;
}) => {
  const [, copyToClipboard] = useCopyToClipboard();

  return (
    <div className="p-3 lg:p-4 w-full flex flex-col items-center">
      <div className={"flex gap-5 justify-between w-full"}>
        <div className={`flex gap-1 items-center`}>
          {data.brandImage && data.brandId && (
            <Link
              to={`/feeds/brands/$brandID`}
              params={{ brandID: data.brandId }}
            >
              <img
                src={data.brandImage}
                alt={data.brandName || "Brand name"}
                className={`bg-[#BCBCBC] rounded-full w-8 h-8 object-contain`}
              ></img>
            </Link>
          )}
          <div>
            {data.brandName && data.brandId && (
              <Link
                to={`/feeds/brands/$brandID`}
                params={{ brandID: data.brandId }}
              >
                <h4 className={`truncate font-[500] text-sm`}>
                  {data.brandName}
                </h4>
              </Link>
            )}
            <p className={"text-xs text-thememutedforeground"}>
              Currently running{" "}
              {numberOfActiveAdsForBrand?.numberOfActiveAds || 0} ads
            </p>
          </div>
        </div>

        <div className={"flex gap-2"}>
          {!IsPublic && data && (
            <ManuallySaveAdToBoard
              open={saveOpen}
              onOpenChange={setSaveOpen}
              data={data}
              variant={"outline"}
            />
          )}

          {data.imageUrl && (
            <Tooltip>
              <TooltipTrigger asChild>
                <a href={data.imageUrl} download={"image.png"}>
                  <Button variant={"outline"} size={"icon"}>
                    <Download className="w-5 h-5" />
                  </Button>
                </a>
              </TooltipTrigger>
              <TooltipContent>
                <p>Download as image</p>
              </TooltipContent>
            </Tooltip>
          )}

          <Tooltip>
            <TooltipTrigger>
              <Button
                onClick={() => {
                  copyToClipboard(
                    `${window.location.origin}/share/inspiration/${data.id}`
                  );
                  showToastNotification("success", {
                    message: "Copied!",
                  });
                }}
                variant={"outline"}
                size={"icon"}
              >
                <LinkIcon />
              </Button>
            </TooltipTrigger>
            <TooltipContent>
              <p>Share a public link</p>
            </TooltipContent>
          </Tooltip>
        </div>
      </div>
      <div
        className={
          "lg:max-w-[23.25rem] w-full border border-themeborder bg-white rounded my-4"
        }
      >
        <div>
          <div className={"p-3"}>
            <div className={"flex gap-5 justify-between"}>
              <div>
                {data.brandName && data.brandId && (
                  <Link
                    to={`/feeds/brands/$brandID`}
                    params={{ brandID: data.brandId }}
                  >
                    <h4 className={`truncate font-[500] text-sm`}>
                      {data.brandName}
                    </h4>
                  </Link>
                )}
              </div>
            </div>
            {adCreativeDetails && (
              <div>
                <p className={"text-xs text-thememutedforeground truncate"}>
                  {adCreativeDetails.description}
                </p>
              </div>
            )}
          </div>
        </div>
        <div>
          {data.imageUrl && (
            <img
              className="w-full"
              alt="ad display"
              loading="lazy"
              src={data.imageUrl}
            />
          )}
          {data.videoUrl && (
            <video
              className="w-full"
              muted
              loop
              controls
              // autoPlay // Ensure video autoplays
              playsInline // For mobile devices (iOS in particular)
              src={data.videoUrl}
            ></video>
          )}
        </div>
        {adCreativeDetails && (
          <div className={"px-3 pt-2 pb-3 flex gap-2"}>
            <div className={"flex-1 max-w-[80%]"}>
              <h3 className={"text-sm font-medium w-full truncate"}>
                {adCreativeDetails.headline}
              </h3>
              <div className="text-xs text-thememutedforeground line-clamp-3">
                {adCreativeDetails?.text}
              </div>
            </div>
            <div className={"w-fit"}>
              {adCreativeDetails.landingPageUrl && (
                <a
                  href={adCreativeDetails.landingPageUrl}
                  target={"_blank"}
                  className={cn(
                    buttonVariants({
                      variant: "secondary",
                      size: "sm",
                    })
                  )}
                >
                  {data.ctaText ?? "CTA"}
                </a>
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
