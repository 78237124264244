import { BoardDialog } from '@/components/BoardDialog.tsx';
import { Stack, Text } from '@/components/custom-components';
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from '@/components/custom-components/Popover';
import { Button } from '@/components/shadcn/button';
import { Input } from '@/components/shadcn/input.tsx';
import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from '@/components/shadcn/tooltip.tsx';
import { LandingAdDataProps } from '@/components/templates/LandingAdCard';
import { BoardProp } from '@/components/templates/Sidebar';
import showToastNotification from '@/hooks/useShowToast.tsx';
import { trpc } from '@/utils/trpc';
import { Check, PlusSquare, Search } from '@mynaui/icons-react';
import { Bookmark } from '@mynaui/icons-react';
import { useEffect, useState } from 'react';
import { Loader } from '../Loader';

interface AddToBoardProps {
  itemType:
    | 'ad'
    | 'email-ad'
    | 'template'
    | 'lander'
    | 'email-template'
    | 'landing-page-template';
  variant?: 'outline' | 'secondary';
  adData?: {
    id: number;
  };
  templateData?: {
    Ad: {
      CreativeID: string | number;
      atID: string;
      AdDbId: number | null;
    };
  };
  emailLanderTemplateData?: LandingAdDataProps;
  landerData?: {
    landerId: number;
  };
  initialSavedState: boolean;
  open?: boolean;
  onOpenChange?: (open: boolean) => void;
}

export function AddToBoard({
  itemType,
  adData,
  templateData,
  emailLanderTemplateData,
  landerData,
  initialSavedState,
  open,
  onOpenChange,
  variant,
}: AddToBoardProps) {
  const [isItemSaved, setIsItemSaved] = useState(initialSavedState);
  const [createBoardDialogOpen, setCreateBoardDialogOpen] = useState(false);
  const [localBoardsState, setLocalBoardsState] = useState<BoardProp[]>([]);
  const [collectionSearchTerm, setCollectionSearchTerm] = useState<string>('');
  const [allowCloseModal, setAllowCloseModal] = useState<boolean>(false);
  const [isOpen, setIsOpen] = useState(false);

  const utils = trpc.useUtils();

  //TRPC Details of Template of Inspo
  const { data: adCreativeDetails } = trpc.getAdCreativeDetails.useQuery(
    { adId: adData?.id ?? 0 },
    {
      enabled: false,
      refetchOnWindowFocus: false,
      refetchOnMount: false,
    },
  );
  const { data: emailDetails } = trpc.getEmailById.useQuery(
    {
      id: adData?.id ?? 0,
    },
    {
      enabled: false,
      refetchOnWindowFocus: false,
      refetchOnMount: false,
    },
  );
  const { data: landerDetails } = trpc.getLander.useQuery(
    {
      landerId: landerData?.landerId ?? 0,
    },
    {
      enabled: false,
      refetchOnWindowFocus: false,
      refetchOnMount: false,
    },
  );

  const checkSaveStatus = async () => {
    switch (itemType) {
      case 'ad':
        if (adCreativeDetails) {
          setIsItemSaved(adCreativeDetails.isSaved);
        }
        break;
      case 'email-ad':
        if (emailDetails) {
          setIsItemSaved(emailDetails.isSaved ?? isItemSaved);
        }
        break;
      case 'lander':
        if (landerDetails) {
          setIsItemSaved(landerDetails.isSaved);
        }
        break;
      case 'template':
        break;
      case 'email-template':
        break;
      case 'landing-page-template':
        break;
    }
  };

  useEffect(() => {
    checkSaveStatus();
  }, [
    adCreativeDetails?.isSaved,
    emailDetails?.isSaved,
    landerDetails?.isSaved,
  ]);

  // TRPC mutations
  const { mutateAsync: saveAd } = trpc.saveAd.useMutation();
  const { mutateAsync: unsaveAd } = trpc.unsaveAd.useMutation();
  const { mutateAsync: saveEmail } = trpc.saveEmail.useMutation();
  const { mutateAsync: unsaveEmail } = trpc.unsaveEmail.useMutation();
  const { mutateAsync: likePost } = trpc.likePost.useMutation();
  const { mutateAsync: removeLike } = trpc.removeLike.useMutation();
  const { mutateAsync: saveLander } = trpc.saveLander.useMutation();
  const { mutateAsync: saveEmailTemplate } =
    trpc.saveEmailTemplate.useMutation();
  const { mutateAsync: unsaveEmailTemplate } =
    trpc.unsaveEmailTemplate.useMutation();
  const { mutateAsync: saveLandingPageTemplate } =
    trpc.saveLandingPageTemplate.useMutation();
  const { mutateAsync: unsaveLandingPageTemplate } =
    trpc.unsaveLandingPageTemplate.useMutation();
  const { mutateAsync: unSaveLander } = trpc.unSaveLander.useMutation();
  const addEmailInspirationToBoard =
    trpc.addEmailInspirationToBoard.useMutation();
  const addAdToBoard = trpc.addAdToBoard.useMutation();
  const removeAdFromBoard = trpc.removeAdFromBoard.useMutation();
  const removeEmailInspirationFromBoard =
    trpc.removeEmailInspirationFromBoard.useMutation();
  const addTemplateToBoard = trpc.addTemplateToBoard.useMutation();
  const addEmailTemplateToBoard = trpc.addEmailTemplateToBoard.useMutation();
  const removeEmailTemplateFromBoard =
    trpc.removeEmailTemplateFromBoard.useMutation();
  const addLandingPageTemplateToBoard =
    trpc.addLandingPageTemplateToBoard.useMutation();
  const removeLandingPageTemplateFromBoard =
    trpc.removeLandingPageTemplateFromBoard.useMutation();
  const removeTemplateFromBoard = trpc.removeTemplateFromBoard.useMutation();
  const addLanderToBoard = trpc.addLanderToBoard.useMutation();
  const removeLanderFromBoard = trpc.removeLanderFromBoard.useMutation();

  const handleOpenChange = (newOpen: boolean) => {
    if (onOpenChange) {
      onOpenChange(newOpen);
    } else {
      setIsOpen(newOpen);
    }
  };

  type SupportedBoardPostType =
    | { postType: 'record'; checkAdId?: string }
    | {
        postType:
          | 'inspiration-ad'
          | 'email-inspiration'
          | 'lander'
          | 'email-template'
          | 'landing-page-template';
        checkAdId?: number;
      };

  const getBoardsQueryParams = (): SupportedBoardPostType => {
    switch (itemType) {
      case 'ad':
        return { checkAdId: adData?.id, postType: 'inspiration-ad' };
      case 'email-ad':
        return { checkAdId: adData?.id, postType: 'email-inspiration' };
      case 'template':
        return {
          checkAdId: templateData?.Ad.atID ? templateData.Ad.atID : undefined,
          postType: 'record',
        };
      case 'email-template':
        return {
          checkAdId: emailLanderTemplateData?.ID
            ? parseInt(emailLanderTemplateData.ID)
            : undefined,
          postType: 'email-template',
        };
      case 'landing-page-template':
        return {
          checkAdId: emailLanderTemplateData?.ID
            ? parseInt(emailLanderTemplateData.ID)
            : undefined,
          postType: 'landing-page-template',
        };
      case 'lander':
        return { checkAdId: landerData?.landerId, postType: 'lander' };
      default:
        return { postType: 'record', checkAdId: undefined };
    }
  };

  const {
    data: boards,
    isLoading,
    refetch,
  } = trpc.getBoards.useQuery(getBoardsQueryParams(), {
    enabled: false,
  });

  useEffect(() => {
    if (open) {
      refetch();
    }
  }, [open, refetch]);

  useEffect(() => {
    if (boards) setLocalBoardsState(boards);
  }, [boards]);

  const handleSaveClick = async (unSave?: boolean) => {
    try {
      if (!isItemSaved) {
        switch (itemType) {
          case 'ad':
            if (adData) {
              await saveAd({ adId: adData.id });
              utils.getAdCreativeDetails.invalidate({ adId: adData.id });
            }
            break;
          case 'email-ad':
            if (adData) {
              await saveEmail({ emailId: adData.id });
              utils.getEmailById.invalidate({ id: adData.id });
            }
            break;
          case 'lander':
            if (landerData) {
              await saveLander({ landerId: landerData.landerId });
              utils.getLander.invalidate({ landerId: landerData.landerId });
            }
            break;
          case 'template':
            if (templateData)
              await likePost({ postID: templateData.Ad.CreativeID.toString() });
            break;
          case 'email-template':
            if (emailLanderTemplateData)
              await saveEmailTemplate({
                emailTemplateId: parseInt(emailLanderTemplateData.ID),
              });
            break;
          case 'landing-page-template':
            if (emailLanderTemplateData)
              await saveLandingPageTemplate({
                landingPageTemplateId: parseInt(emailLanderTemplateData.ID),
              });
            break;
        }

        setIsItemSaved(true);
        showToastNotification('success', { message: `Item saved` });
      } else if (unSave) {
        switch (itemType) {
          case 'ad':
            if (adData) {
              await unsaveAd({ adId: adData.id });
              utils.getAdCreativeDetails.invalidate({ adId: adData.id });
            }
            break;
          case 'email-ad':
            if (adData) {
              await unsaveEmail({ emailId: adData.id });
              utils.getEmailById.invalidate({ id: adData.id });
            }
            break;
          case 'lander':
            if (landerData) {
              await unSaveLander({ landerId: landerData.landerId });
              utils.getLander.invalidate({ landerId: landerData.landerId });
            }
            break;
          case 'template':
            if (templateData && templateData.Ad.AdDbId)
              await unsaveAd({ adId: templateData.Ad.AdDbId });
            else if (templateData) {
              await removeLike({
                postID: templateData.Ad.CreativeID.toString(),
              });
            }
            break;
          case 'email-template':
            if (emailLanderTemplateData) {
              await unsaveEmailTemplate({
                emailTemplateId: parseInt(emailLanderTemplateData.ID),
              });
              utils.getEmailById.invalidate({
                id: parseInt(emailLanderTemplateData.ID),
              });
            }
            break;
          case 'landing-page-template':
            if (emailLanderTemplateData) {
              await unsaveLandingPageTemplate({
                landingPageTemplateId: parseInt(emailLanderTemplateData.ID),
              });
            }
            break;
        }
        setIsItemSaved(false);
        showToastNotification('success', { message: `Item unsaved` });
      } else {
        return;
      }
      utils.getAdTemplateSaves.invalidate(undefined, { refetchType: 'all' });
      utils.getAllSavedAds.invalidate();
      utils.getAllSavedAdTemplates.invalidate();
      utils.getAllSavedEmailTemplates.invalidate();
      utils.getAllSavedLandingPageTemplates.invalidate();
      utils.getAllSavedEmails.invalidate();
    } catch (error) {
      console.error('Failed to save/unsave item:', error);
      showToastNotification('error', {
        message: 'An error occurred while saving/unsaving the item.',
      });
    }
  };

  const handleBoardToggle = async (boardId: string, isChecked: boolean) => {
    setLocalBoardsState((prevBoards) =>
      prevBoards.map((board) =>
        board.id === boardId ? { ...board, isInBoard: isChecked } : board,
      ),
    );

    try {
      if (isChecked) {
        switch (itemType) {
          case 'ad':
            if (adData)
              await addAdToBoard.mutateAsync({ boardId, adId: adData.id });
            break;
          case 'email-ad':
            if (adData)
              await addEmailInspirationToBoard.mutateAsync({
                boardId,
                emailId: adData.id,
              });
            break;
          case 'template':
            if (templateData)
              await addTemplateToBoard.mutateAsync({
                boardId,
                templateId: templateData.Ad.atID,
              });
            break;
          case 'email-template':
            if (emailLanderTemplateData)
              await addEmailTemplateToBoard.mutateAsync({
                boardId,
                templateId: parseInt(emailLanderTemplateData.ID),
              });
            break;
          case 'landing-page-template':
            if (emailLanderTemplateData)
              await addLandingPageTemplateToBoard.mutateAsync({
                boardId,
                templateId: parseInt(emailLanderTemplateData.ID),
              });
            break;
          case 'lander':
            if (landerData)
              await addLanderToBoard.mutateAsync({
                boardId,
                landerId: landerData.landerId,
              });
            break;
        }
        showToastNotification('success', { message: `Added to collection!` });
      } else {
        switch (itemType) {
          case 'ad':
            if (adData)
              await removeAdFromBoard.mutateAsync({ boardId, adId: adData.id });
            break;
          case 'email-ad':
            if (adData)
              await removeEmailInspirationFromBoard.mutateAsync({
                boardId,
                emailId: adData.id,
              });
            break;
          case 'template':
            if (templateData)
              await removeTemplateFromBoard.mutateAsync({
                boardId,
                templateId: templateData.Ad.atID,
              });
            break;
          case 'email-template':
            if (emailLanderTemplateData)
              await removeEmailTemplateFromBoard.mutateAsync({
                boardId,
                templateId: parseInt(emailLanderTemplateData.ID),
              });
            break;
          case 'landing-page-template':
            if (emailLanderTemplateData)
              await removeLandingPageTemplateFromBoard.mutateAsync({
                boardId,
                templateId: parseInt(emailLanderTemplateData.ID),
              });
            break;
          case 'lander':
            if (landerData)
              await removeLanderFromBoard.mutateAsync({
                boardId,
                landerId: landerData.landerId,
              });
            break;
        }
        showToastNotification('success', {
          message: `Removed from collection!`,
        });
      }
      utils.getBoards.invalidate();
      utils.getBoardDetails.invalidate();
      utils.getBoardEmails.invalidate();
    } catch (error) {
      console.error(error);
      showToastNotification('error', {
        message: 'An error occurred while updating the collection.',
      });
    }
  };

  return (
    <Popover
      open={open !== undefined ? open : isOpen}
      onOpenChange={(newValue: boolean) => {
        /**
         * Using the popover in another modal makes it behave weirdly on first open. It immediately closes
         * This is mainly because in the details, it is a modal in a modal
         * The check below is to prevent that from happening
         */
        if (newValue) {
          setAllowCloseModal(false);
          setTimeout(() => {
            setAllowCloseModal(true);
          }, 1000);
        } else if (!allowCloseModal) return;

        handleOpenChange(newValue);
      }}
    >
      {createBoardDialogOpen && (
        <BoardDialog
          open={createBoardDialogOpen}
          onOpenChange={() => setCreateBoardDialogOpen(false)}
          adId={itemType === 'ad' ? adData?.id : undefined}
          inspirationLanderId={
            itemType === 'lander' ? landerData?.landerId : undefined
          }
          templateId={
            itemType === 'template' ? templateData?.Ad.atID : undefined
          }
          emailTemplateId={
            itemType === 'email-template'
              ? emailLanderTemplateData?.ID
              : undefined
          }
          landerTemplateId={
            itemType === 'landing-page-template'
              ? emailLanderTemplateData?.ID
              : undefined
          }
        />
      )}
      <Tooltip>
        <TooltipTrigger asChild>
          <PopoverTrigger asChild>
            <Button
              onClick={() => handleSaveClick()}
              variant={variant ?? 'secondary'}
              className={`${isItemSaved ? 'bg-purple-200' : ''}`}
              size={'icon'}
            >
              {isItemSaved ? (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                >
                  <path
                    d="M6.27268 17.3676C5.71755 17.728 5 17.3103 5 16.6267V3.28469C5 2.85132 5.27982 2.5 5.625 2.5H14.375C14.7202 2.5 15 2.85132 15 3.28469V16.6267C15 17.3103 14.2825 17.728 13.7273 17.3676L10.4393 15.2332C10.1702 15.0585 9.82976 15.0585 9.56065 15.2332L6.27268 17.3676Z"
                    fill="#673BEC"
                    stroke="#673BEC"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              ) : (
                <Bookmark className="w-5 h-5" />
              )}
            </Button>
          </PopoverTrigger>
        </TooltipTrigger>
        <TooltipContent>
          <p>Add to My Collections</p>
        </TooltipContent>
      </Tooltip>
      <PopoverContent className="border-none w-56 p-1 rounded-md" align="end">
        <div className="relative">
          {/* Search Icon positioned on the left */}
          <span className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
            <Search className="h-5 w-5 text-gray-400" aria-hidden="true" />
          </span>
          <Input
            type={'text'}
            onChange={(e) => setCollectionSearchTerm(e.target.value)}
            placeholder="Search Collections"
            className="pl-10 w-full border-none focus-visible:ring-0 focus-visible:ring-transparent focus-visible:ring-offset-0" // Padding left to make space for the icon
          />
        </div>
        <hr className={'border-themeinput'} />
        {!isItemSaved && (
          <div className="px-2 py-1.5">
            <Text className="text-sm font-semibold leading-5 text-theme-popover-foreground">
              Add to board
            </Text>
          </div>
        )}
        {isItemSaved && (
          <Stack>
            <div
              onClick={() => handleSaveClick(true)}
              className="flex items-center gap-2  pl-2 py-1.5 pr-2 w-full my-1 hover:bg-thememuted rounded-sm"
            >
              <div className="w-4 h-4">
                <Check className="w-4 h-4" />
              </div>

              <label
                htmlFor={'All Saved'}
                className="text-theme-popover-foreground text-sm leading-5 font-normal"
              >
                {'All Saved'}
              </label>
            </div>
          </Stack>
        )}
        <hr className={'border-themeinput'} />

        <div className=" h-auto max-h-48 overflow-auto">
          <div
            onClick={() => handleSaveClick(true)}
            className="flex items-center gap-2  pl-2 my-2 pr-2 w-full rounded-sm"
          >
            <Text
              weight={'medium'}
              size={'xs'}
              className="text-thememutedforeground"
            >
              Add to Collection
            </Text>
          </div>
          {isLoading ? (
            <div className="w-full flex justify-center my-10">
              <Loader />
            </div>
          ) : (
            (collectionSearchTerm
              ? localBoardsState.filter((localBoard) =>
                  localBoard.name
                    .toLowerCase()
                    .includes(collectionSearchTerm.toLowerCase()),
                )
              : localBoardsState
            ).map((board) => (
              <div
                key={board.id}
                className="flex items-center gap-2  pl-2 py-1.5 pr-2 w-full my-1 hover:bg-thememuted rounded-sm"
                onClick={() => handleBoardToggle(board.id, !board.isInBoard)}
              >
                <div className="w-4 h-4">
                  {board.isInBoard && <Check className="w-4 h-4" />}
                </div>

                <label
                  htmlFor={board.name}
                  className="text-theme-popover-foreground text-sm leading-5 font-normal"
                >
                  {board.name}
                </label>
              </div>
            ))
          )}
        </div>
        <hr className={'border-themeinput'} />
        <div
          onClick={() => setCreateBoardDialogOpen(true)}
          className="px-2 py-2 flex items-center gap-2 cursor-pointer"
        >
          <PlusSquare className="h-4 w-4" />
          <Text className="text-sm font-normal leading-5 text-theme-popover-foreground">
            Create New Board
          </Text>
        </div>
      </PopoverContent>
    </Popover>
  );
}
