import {
  MasonryComponent,
  TypedResponsiveMasonry,
} from '@/components/ResponsiveMasonryWrapper.tsx';
import InspirationTabs from '@/components/ad-inspiration/InspirationTabs.tsx';
import {
  CollectionFilterSelect,
  GroupCollections,
} from '@/components/ad-inspiration/OrderFilterSelect.tsx';
import { Loader } from '@/components/custom-components/Loader';
import { ErrorDisplay } from '@/components/error.tsx';
import { Button } from '@/components/shadcn/button.tsx';
import {
  EmailFeedAdCard,
  LandingAdCard,
} from '@/components/templates/LandingAdCard';
import useCardLayoutWithImpressions from '@/hooks/useCardLayoutWithImpressions.tsx';
import { useCopyToClipboard } from '@/hooks/useCopyToClipboard.tsx';
import { featureFlagKeys } from '@/utils/data/featureFlags.ts';
import {
  getRequestTabs,
  showTemplateRecentButton,
} from '@/utils/data/feeds.ts';
import { trpc } from '@/utils/trpc.ts';
import { Link as LinkIcon } from '@mynaui/icons-react';
import { createFileRoute, useNavigate } from '@tanstack/react-router';
import { ArrowLeftIcon } from 'lucide-react';
import { useFeatureFlagEnabled } from 'posthog-js/react';
import { useEffect, useState } from 'react';
import { useInView } from 'react-intersection-observer';
import { toast } from 'sonner';
import { EmailFeedContent } from '../../../../src/server/services/emails/definitions.ts';

type SearchParams = {
  groupFilter?: GroupCollections;
  sideBarOpen?: boolean;
};

export const Route = createFileRoute('/feeds/requested/emails/')({
  component: All,
  validateSearch: (search: Record<string, unknown>): SearchParams => {
    const groupFilter = search?.groupFilter as GroupCollections;
    const sideBarOpen = search?.sideBarOpen as boolean;

    return {
      groupFilter,
      sideBarOpen,
    };
  },
});

function All() {
  const { groupFilter } = Route.useSearch();

  // const [allData, setAllData] = useState<Ad[] | undefined>(undefined);
  const [, copyToClipboard] = useCopyToClipboard();

  const navigate = useNavigate();

  const { squareRef, columns, gutterWidth } = useCardLayoutWithImpressions();

  const [allData, setAllData] = useState<EmailFeedContent[] | undefined>(
    undefined,
  );

  // const currentLocation = location.pathname
  const flagEnabled = useFeatureFlagEnabled(featureFlagKeys.emailFeeds);
  const {
    data: requestedEmails,
    fetchNextPage,
    isLoading,
    isError,
    isRefetching,
    hasNextPage,
    isFetchingNextPage,
  } = trpc.getAllRequestedEmailTemplates.useInfiniteQuery(
    {
      limit: 20,
    },
    {
      getNextPageParam: (lastPage) => lastPage.nextCursor,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
    },
  );

  const { ref: scrollRef, inView } = useInView({
    threshold: 0,
    trackVisibility: true,
    delay: 100,
    initialInView: false,
  });

  useEffect(() => {
    if (
      inView &&
      hasNextPage &&
      !isFetchingNextPage &&
      requestedEmails &&
      allData &&
      allData.length &&
      requestedEmails.pages[requestedEmails.pages.length - 1].emails.length !==
        0
    ) {
      fetchNextPage();
    }
  }, [inView, hasNextPage, fetchNextPage, requestedEmails]);

  useEffect(() => {
    if (!requestedEmails) return;
    setAllData(() => undefined);
    const records = [] as EmailFeedContent[];
    for (const page of requestedEmails.pages) {
      records.push(...page.emails);
    }
    setAllData(() =>
      records.filter(
        (item, index) =>
          index === records.findIndex((obj) => obj.id === item.id),
      ),
    );
  }, [requestedEmails]);

  if (isError) {
    return (
      <div className="px-10">
        <ErrorDisplay />
      </div>
    );
  }

  return (
    <>
      <div className={''}>
        <div id={'app-header'} className={''}>
          <Button
            onClick={() =>
              navigate({
                to: '/feeds/collections',
              })
            }
            variant={'ghost'}
            className={'flex gap-1.5 items-center'}
          >
            <ArrowLeftIcon size={'1rem'} />
            <span>All Collections</span>
          </Button>
          <div className={'lg:flex justify-between gap-5 items-center mt-2'}>
            <div
              className={
                'flex justify-between lg:justify-start gap-5 items-center'
              }
            >
              <div className={''}>
                <h1 className={'text-2xl font-semibold'}>
                  Requested Templates
                </h1>
                <p
                  className={
                    'text-sm lg:text-base text-thememutedforeground mt-2'
                  }
                >
                  You requested templates for all of these assets. We’ll let you
                  know when they’re available!
                </p>
              </div>
            </div>
            <div className={'flex lg:flex-col gap-2 items-center mt-2 lg:mt-0'}>
              <Button
                onClick={() => {
                  copyToClipboard(`${window.location.origin}/feeds/requested`);
                  toast.success('Copied!');
                }}
                className={'hidden md:flex'}
                variant={'outline'}
                size={'sm'}
              >
                <LinkIcon /> Share
              </Button>
              <div>
                <span
                  className={
                    'text-thememutedforeground text-right text-sm lg:text-base'
                  }
                >
                  {allData?.length || 0} items
                </span>
              </div>
            </div>
          </div>
          <div className={'mt-8'}>
            <div className={'flex gap-5 items-center justify-between'}>
              <div className={'flex gap-6 items-center'}>
                <InspirationTabs
                  pages={getRequestTabs({ showEmailFeeds: flagEnabled })}
                />
                <CollectionFilterSelect
                  defaultFilter={groupFilter || 'all'}
                  options={[
                    { label: 'All Requested', value: 'all' },
                    { label: 'Only Templates', value: 'only-templates' },
                  ]}
                  type={'requested'}
                />
              </div>
              <div>
                <Button
                  onClick={() => {
                    copyToClipboard(
                      `${window.location.origin}/feeds/inspiration/landing-pages`,
                    );
                    toast.success('Copied!');
                  }}
                  className={'flex md:hidden'}
                  variant={'outline'}
                  size={'icon'}
                >
                  <LinkIcon />
                </Button>
              </div>
            </div>
          </div>
        </div>
        <div className="mt-8">
          {isLoading || isRefetching ? (
            <div className="flex justify-center items-center w-full h-screen">
              <Loader />
            </div>
          ) : (
            <div>
              {allData && (
                <>
                  {(
                    groupFilter == 'all' || groupFilter === undefined
                      ? (allData ?? []).length == 0
                      : (allData ?? []).filter((data) => data.template)
                          .length == 0
                  ) ? (
                    <div className={'lg:h-96 flex justify-center items-center'}>
                      <p>No template requests added to this collection</p>
                    </div>
                  ) : (
                    <div ref={squareRef} className={'relative w-full pb-10'}>
                      <div>
                        <TypedResponsiveMasonry
                          columnsCountBreakPoints={
                            columns ? { 0: columns } : {}
                          } // Columns is determined by the width of the container
                        >
                          <MasonryComponent gutter={gutterWidth / 16 + 'rem'}>
                            {(allData ?? []).map((ad) => {
                              return (groupFilter === 'all' ||
                                groupFilter === undefined) &&
                                !ad.template ? (
                                <EmailFeedAdCard
                                  key={ad.id}
                                  adData={ad}
                                  IsRequested={true}
                                />
                              ) : ad.template ? (
                                <LandingAdCard
                                  key={ad.template?.['Email ID']}
                                  adData={{
                                    Screenshot: ad.template['Email Screenshot'],
                                    ID: ad.template['Email ID'].toString(),
                                    created: ad.template.Created,
                                    category: ad.template.Category,
                                    isSaved: ad.template.isSaved,
                                    imageUrl: ad.template.imageUrl,
                                  }}
                                  showTemplateButton
                                  showRecentButton={showTemplateRecentButton(
                                    ad.template?.Created,
                                  )}
                                  type={'landing-page'}
                                />
                              ) : (
                                <></>
                              );
                            })}
                          </MasonryComponent>
                        </TypedResponsiveMasonry>
                      </div>
                    </div>
                  )}
                </>
              )}
            </div>
          )}
          <div
            ref={scrollRef}
            className="flex justify-center w-full min-h-14 h-14"
          >
            {isFetchingNextPage && <Loader />}
          </div>
        </div>
      </div>
    </>
  );
}

export default All;
