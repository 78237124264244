import {
  MasonryComponent,
  TypedResponsiveMasonry,
} from "@/components/ResponsiveMasonryWrapper.tsx";
import { OrderFilterSelect } from "@/components/ad-inspiration/OrderFilterSelect.tsx";
import { Stack, Text } from "@/components/custom-components";
import { FeatureTabs } from "@/components/custom-components/FeatureTabs/index.tsx";
import { Loader } from "@/components/custom-components/Loader";
import { TemplatesFilterPopover } from "@/components/custom-components/TemplateFilterPopover/index.tsx";
import { ErrorDisplay } from "@/components/error.tsx";
import FirstTimeWelcomeDialog from "@/components/onboarding/FirstTimeWelcomeDialog.tsx";
import { Button } from "@/components/shadcn/button.tsx";
import { AdCard } from "@/components/templates/AdCard";
import { AdGridView } from "@/components/templates/AdGridView";
import useCardLayoutWithImpressions from "@/hooks/useCardLayoutWithImpressions.tsx";
import { AdFeedFilterOption } from "@/hooks/useFilterFeed.tsx";
import { showTemplateRecentButton } from "@/utils/data/feeds.ts";
import { TrendingUp } from "@mynaui/icons-react";
import { Shuffle } from "@mynaui/icons-react";
import { createFileRoute, useNavigate } from "@tanstack/react-router";
import { useCallback, useEffect, useState } from "react";
import { useInView } from "react-intersection-observer";
import { z } from "zod";
import {
  AirTableAdRecord,
  orderFilter as OrderFilterType,
} from "../../../../src/shared/airtable/index.ts";
import { trpc } from "../utils/trpc.ts";

export type SelectedTemplateFilters = {
  collectionIds: string[];
  brands?: string[];
};

type SearchParams = {
  collections?: string;
  getStarted?: string;
  sideBarOpen?: boolean;
  orderFilter?: z.infer<typeof OrderFilterType>;
  brands?: string;
};

export type FilterOption = {
  title: string;
  counter: number;
  optionItems: { label: string; value?: boolean,  logo?: string; id?: string }[];
};

export const Route = createFileRoute("/feeds/templates/")({
  component: All,
  validateSearch: (search: Record<string, unknown>): SearchParams => {
    const collections = search?.collections as string | undefined;
    const getStarted = search?.getStarted as string | undefined;
    const sideBarOpen = search?.sideBarOpen as boolean;
    const brands = search?.brands as string | undefined;
    const orderFilter = search?.orderFilter as
      | z.infer<typeof OrderFilterType>
      | undefined;

    return {
      collections,
      getStarted,
      sideBarOpen,
      orderFilter,
      brands,
    };
  },
});

function All() {
  const {
    collections: queryCollections,
    getStarted,
    orderFilter,
    brands: queryBrands,
  } = Route.useSearch();
  const navigate = useNavigate();

  const [getStartedDialogOpen, setGetStartedDialogOpen] =
    useState<boolean>(false);

  useEffect(() => {
    if (getStarted === "ready" && !getStartedDialogOpen) {
      setGetStartedDialogOpen(true); // Prevent unnecessary updates
    }
  }, [getStarted, getStartedDialogOpen]);

  const [allData, setAllData] = useState<AirTableAdRecord[] | undefined>(
    undefined
  );
  const [filterOptions, setFilterOptions] = useState<AdFeedFilterOption[]>([]);
  const [cursor, setCursor] = useState(0);
  // const [selectedCollectionIds, setSelectedCollectionIds] = useState<string[]>(
  //   [],
  // );
  const [unfilteredDataLength, setUnfilteredDataLength] = useState<number>(0);

  const [selectedFilters, setSelectedFilters] =
    useState<SelectedTemplateFilters>({
      collectionIds: queryCollections ? queryCollections.split(",") : [],
      brands: queryBrands ? (queryBrands.split(",") as string[]) : undefined,
    });

  const { data: brands } = trpc.getBrands.useQuery(undefined, {
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
  });

  // Get the template collections to be passed to the filter
  const { data: templateCollections } = trpc.getAllCollections.useQuery(
    {},
    {
      refetchOnWindowFocus: false,
      refetchOnMount: false,
    }
  );
  const { data: newlyAddedTemplateCount } =
    trpc.getNewlyAddedTemplatesCount.useQuery(
      { templatesType: "record", daysToCheck: 30 },
      {
        refetchOnWindowFocus: false,
        refetchOnMount: false,
      }
    );

  // Update the filters after fetching the templates
  useEffect(() => {
    if (
      templateCollections &&
      templateCollections.length > 0 &&
      brands &&
      brands.length > 0
    ) {
      setFilterOptions((prevOptions) => {
        if (prevOptions.length > 0) return prevOptions; // Avoid redundant updates
        return [
          {
            title: "Collection",
            counter: 0,
            optionItems: templateCollections.map((i) => ({
              label: i.Title,
              value: false,
              id: i.atID,
            })),
          },
          {
            title: "Brand",
            counter: 0,
            optionItems: brands.map((i) => ({
              label: i.brandName,
              value: false,
              id: i.brandId,
              logo: i.brandImage ?? undefined,
            })),
          },
        ];
      });
    }
  }, [templateCollections, brands]);

  // Update AdFilter options based on selectedFilters
  useEffect(() => {
    if (filterOptions.length === 0 || !selectedFilters) return;

    const updatedOptions = filterOptions.map((option) => ({
      ...option,
      optionItems: option.optionItems.map((item) => ({
        ...item,
        value:
          (option.title === "Collection" &&
            selectedFilters.collectionIds?.includes(item.id ?? "")) ||
          (option.title === "Brand" &&
            selectedFilters.brands?.includes(item.id ?? "")),
      })),
    }));

    setFilterOptions((prev) => {
      if (JSON.stringify(prev) === JSON.stringify(updatedOptions)) return prev;
      return updatedOptions;
    });
  }, [filterOptions, selectedFilters]);

  const updateQueryString = useCallback(
    (params: { collections?: string; brands?: string }) => {
      const searchParams = new URLSearchParams();

      if (params.collections) searchParams.set('collections', params.collections);
      if (params.brands) searchParams.set('brands', params.brands);

      navigate({
        to: "/feeds/templates",
        replace: true,
        search: (old) => ({ ...old, ...params, orderFilter }),
      });
    },
    [navigate, orderFilter]
  );

  const handleOptionsChange = useCallback(
    (options: AdFeedFilterOption[]) => {
      const selectedCollections: string[] = [];
      const selectedBrands: string[] = [];

      options.forEach((group) => {
        group.optionItems.forEach((item) => {
          if (item.value) {
            if (group.title === "Collection")
              selectedCollections.push(`${item.id}`);
            if (group.title === "Brand") 
              selectedBrands.push(`${item.id}`);
          }
        });
      });

      setSelectedFilters({
        collectionIds:
          selectedCollections.length > 0 ? selectedCollections : [],
        brands: selectedBrands.length > 0 ? selectedBrands : undefined,
      });

      updateQueryString({
        collections:
          selectedCollections.length > 0
            ? selectedCollections.join(",")
            : undefined,
        brands:
          selectedBrands.length > 0 ? selectedBrands.join(",") : undefined,
      });
    },
    [updateQueryString]
  );

  useEffect(() => {
    if (!selectedFilters) return;
    const params: Record<string, string | undefined> = {};
    setCursor(0);
    if (selectedFilters.collectionIds)
      params.collections = selectedFilters.collectionIds.join(",");
    if (selectedFilters.brands)
      params.brands = selectedFilters.brands.join(",");
  }, [selectedFilters]);

  // check for if there is at least one option selected
  const isAnyFilterSelected = (filters: SelectedTemplateFilters) => {
    return filters.collectionIds && filters.collectionIds.length > 0 || filters.brands && filters.brands.length > 0;
  };

  const {
    data: filteredTemplatesByCollections,
    fetchNextPage,
    refetch,
    isLoading,
    isError,
    isFetchingNextPage,
    hasNextPage,
  } = trpc.filterTemplatesByCollections.useInfiniteQuery(
    {
      ...selectedFilters,
      sortingOptions: orderFilter,
      Tags: undefined,
      limit: 20,
      Ready: true,
    },
    {
      getNextPageParam: (lastPage) => lastPage.nextCursor,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      enabled: Boolean(isAnyFilterSelected(selectedFilters)),
      initialCursor: cursor,
    }
  );

  const { ref: scrollRef, inView } = useInView({
    threshold: 0,
    trackVisibility: true,
    delay: 100,
    initialInView: false,
  });

  useEffect(() => {
    if (
      inView &&
      hasNextPage &&
      !isFetchingNextPage &&
      filteredTemplatesByCollections &&
      allData &&
      allData.length &&
      unfilteredDataLength <
        filteredTemplatesByCollections.pages[0].TotalRecords
    ) {
      fetchNextPage();
    }
  }, [inView, hasNextPage, fetchNextPage, filteredTemplatesByCollections]);

  useEffect(() => {
    if (!filteredTemplatesByCollections) return;

    setAllData(() => undefined);

    const records = [] as AirTableAdRecord[];
    for (const page of filteredTemplatesByCollections.pages) {
      records.push(...page.ATRecords);
    }
    setAllData(() =>
      records.filter(
        (item, index) =>
          index === records.findIndex((obj) => obj.atID === item.atID)
      )
    );
    setUnfilteredDataLength(records.length);
  }, [filteredTemplatesByCollections]);

  const { squareRef, desiredCardWidth, columns, gutterWidth } =
    useCardLayoutWithImpressions();

  return (
    <>
      {getStartedDialogOpen && (
        <FirstTimeWelcomeDialog
          open={getStartedDialogOpen}
          onOpenChange={() => setGetStartedDialogOpen(false)}
        />
      )}
      <Stack className="gap-3 lg:gap-6 relative">
        <Stack className="gap-3 lg:gap-8">
          <FeatureTabs
            tabItems={[
              {
                name: "Ads",
                link: "/feeds/templates",
              },
              {
                name: "Emails",
                link: "/feeds/templates/emails",
                isPremiumFeature: true,
              },
              {
                name: "Landers",
                link: "/feeds/templates/landing-pages",
                isPremiumFeature: true,
              },
            ]}
          />
          <Stack className="gap-3 lg:gap-6">
            <div className={"flex justify-between gap-5 items-center"}>
              <div>
                <Text weight="semibold" size={"xxl"} className="w-fit">
                  Yours. For The Making.
                </Text>
                <Text
                  weight="normal"
                  size={"lg"}
                  className="text-thememutedforeground hidden lg:flex"
                >
                  Shape ideas into standout ads with endless possibilities.
                </Text>
              </div>
              <div className={"flex gap-2 items-center flex-wrap justify-end"}>
                {(newlyAddedTemplateCount ?? 0) > 0 && (
                  <div className="flex gap-2 w-fit items-center">
                    <TrendingUp className="text-themedestructive" />
                    <Text
                      size={"sm"}
                      weight={"medium"}
                      className="text-thememutedforeground "
                    >
                      {newlyAddedTemplateCount} New{" "}
                      {newlyAddedTemplateCount == 1 ? "Template" : "Templates"}
                    </Text>
                  </div>
                )}

                <div className="lg:hidden">
                  <OrderFilterSelect
                    defaultFilter={orderFilter || "Random"}
                    options={["Random", "Recent", "Popular", "Oldest"]}
                  />
                </div>
                <Button
                  size={"sm"}
                  variant={"brandGradient"}
                  className="h-8"
                  onClick={() => {
                    if (orderFilter !== "Random") {
                      navigate({
                        search: (old) => {
                          return {
                            ...old,
                            orderFilter: "Random",
                          };
                        },
                        params: (old) => {
                          return {
                            ...old,
                          };
                        },
                      });
                    } else {
                      refetch();
                    }
                  }}
                >
                  <Shuffle className="h-5" />
                  Shuffle
                </Button>
              </div>
            </div>
          </Stack>
        </Stack>

        <div
          className={`z-10 lg:sticky lg:top-0 lg:bg-white lg:py-2 flex ${filterOptions && filterOptions.length > 0 ? "justify-between" : "justify-end"} items-center`}
        >
          <div>
            {filterOptions && filterOptions.length > 0 && (
              <TemplatesFilterPopover
                initialOptions={filterOptions}
                onOptionsChange={handleOptionsChange}
                placeholder={"Filter Ad Templates"}
              />
            )}
          </div>

          <div className={"hidden lg:flex"}>
            <OrderFilterSelect
              defaultFilter={orderFilter || "Random"}
              options={["Random", "Recent", "Popular", "Oldest"]}
            />
          </div>
        </div>
        {isLoading ? (
          <div className="flex justify-center items-center w-full h-[70vh]">
            <Loader />
          </div>
        ) : isError ? (
          <ErrorDisplay />
        ) : filteredTemplatesByCollections ? (
          <div>
            {allData && allData.length === 0 ? (
              <div className="flex flex-col justify-center items-center">
                <p className="text-center w-4/5 lg:w-1/2 mb-5">
                  Looks like you've gone down a path with no inspiration...this
                  is your fault! All we do is win...but really, maybe try a
                  different configuration of filters - we got you!
                </p>
                <img src="/giphy.webp" width="480" height="270" alt="" />
              </div>
            ) : (
              <div ref={squareRef} className={"relative w-full lg:px-0 pb-10"}>
                {filteredTemplatesByCollections.pages[0].TotalRecords === 0 ? (
                  <div className={"lg:h-96 flex justify-center items-center"}>
                    <p>No templates added to this collection</p>
                  </div>
                ) : (
                  allData && (
                    <div>
                      <TypedResponsiveMasonry
                        columnsCountBreakPoints={columns ? { 0: columns } : {}} // Columns is determined by the width of the container
                      >
                        <MasonryComponent gutter={gutterWidth / 16 + "rem"}>
                          {allData.map((ad) => {
                            return (
                              <AdCard
                                key={ad.atID}
                                adData={{
                                  Ad: ad,
                                  IsLocked: false,
                                  IsPublic: false,
                                  brandName: undefined,
                                  shouldInvalidateCache: false,
                                  DesiredWidth: desiredCardWidth,
                                }}
                                showRecentButton={showTemplateRecentButton(
                                  ad.Created
                                )}
                              />
                            );
                          })}
                        </MasonryComponent>
                      </TypedResponsiveMasonry>
                    </div>
                  )
                )}
                <div className={"relative"}>
                  <div
                    className={
                      "absolute w-[10px] h-[1500px] transform translate-y-[-1500px]" // Having the height be 1500px helps when the masonry grid has one column longer than another
                    }
                    ref={scrollRef}
                  ></div>
                </div>{" "}
                <div className={"relative"}>
                  <div
                    className={
                      "absolute w-[10px] h-[1500px] transform translate-y-[-1500px]" // Having the height be 1500px helps when the masonry grid has one column longer than another
                    }
                    ref={scrollRef}
                  ></div>
                </div>
              </div>
            )}
          </div>
        ) : (
          !isAnyFilterSelected(selectedFilters) && (
            <AdGridView
              Filter={{
                Expert: undefined,
                Tags: undefined,
                loadAdsCreatedAfter: undefined,
                Ready: true,
                cursor: undefined,
                limit: 20,
                sortingOptions: orderFilter,
              }}
            />
          )
        )}
        <div
          ref={scrollRef}
          className="flex justify-center w-full min-h-14 h-14"
        >
          {isFetchingNextPage && <Loader />}
        </div>
      </Stack>
    </>
  );
}
