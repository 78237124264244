import {
  MasonryComponent,
  TypedResponsiveMasonry,
} from '@/components/ResponsiveMasonryWrapper.tsx';
import { OrderFilterSelect } from '@/components/ad-inspiration/OrderFilterSelect.tsx';
import { Stack, Text } from '@/components/custom-components';
import { Loader } from '@/components/custom-components/Loader';
import { ErrorDisplay } from '@/components/error.tsx';
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  BreadcrumbList,
  BreadcrumbPage,
  BreadcrumbSeparator,
} from '@/components/shadcn/breadcrumb.tsx';
import { Button } from '@/components/shadcn/button.tsx';
import { AdCard } from '@/components/templates/AdCard';
import useCardLayoutWithImpressions from '@/hooks/useCardLayoutWithImpressions.tsx';
import { useCopyToClipboard } from '@/hooks/useCopyToClipboard.tsx';
import showToastNotification from '@/hooks/useShowToast.tsx';
import { trpc } from '@/utils/trpc.ts';
import { Link as LinkIcon } from '@mynaui/icons-react';
import { createFileRoute } from '@tanstack/react-router';
import { Link } from '@tanstack/react-router';
import { useEffect, useState } from 'react';
import { useInView } from 'react-intersection-observer';
import { z } from 'zod';
import {
  AirTableAdRecord,
  orderFilter as OrderFilterType,
} from '../../../../src/shared/airtable';

type SearchParams = {
  sideBarOpen?: boolean;
  orderFilter?: z.infer<typeof OrderFilterType>;
};

export const Route = createFileRoute('/feeds/collections/$collectionID')({
  component: CollectionTemplates,
  validateSearch: (search: Record<string, unknown>): SearchParams => {
    const sideBarOpen = search?.sideBarOpen as boolean;
    const orderFilter = search?.orderFilter as
      | z.infer<typeof OrderFilterType>
      | undefined;

    return {
      sideBarOpen,
      orderFilter,
    };
  },
});

function CollectionTemplates() {
  const { orderFilter } = Route.useSearch();
  const [allData, setAllData] = useState<AirTableAdRecord[] | undefined>(
    undefined,
  );
  const { data: permissionData } = trpc.permissions.useQuery(null, {});
  const { collectionID } = Route.useParams();
  const [, copyToClipboard] = useCopyToClipboard();

  const {
    data: collectionDetails,
    fetchNextPage,
    isLoading,
    isRefetching,
    error,
  } = trpc.getCollectionsDetails.useInfiniteQuery(
    {
      collectionId: collectionID,
      Expert: undefined,
      Tags: undefined,
      loadAdsCreatedAfter: undefined,
      Ready: true,
      // cursor: undefined,
      limit: 20,
      sortingOptions: orderFilter,
    },
    {
      getNextPageParam: (lastPage) => lastPage.nextCursor,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
    },
  );

  // Ref for trigger to fetch next page
  const { ref: scrollRef, inView } = useInView({
    threshold: 0,
    trackVisibility: true,
    delay: 100,
    initialInView: false,
  });

  useEffect(
    function fetchNextPageWhenElemInView() {
      if (!inView || !collectionDetails) {
        return;
      }
      if (!allData) {
        return;
      }
      if (!allData.length) {
        return;
      }
      // We've fetched all the data if these are equal
      if (collectionDetails.pages[0].TotalRecords <= allData?.length) {
        return;
      }
      fetchNextPage();
    },

    [inView, collectionDetails, allData, fetchNextPage],
  );

  // Syncs react state with trpc state
  useEffect(
    function addFetchedAtaToReactState() {
      if (!collectionDetails) return;
      setAllData(() => undefined);
      const records = [] as AirTableAdRecord[];
      for (const page of collectionDetails.pages) {
        records.push(...page.ATRecords);
      }
      setAllData(() => records);
    },
    [collectionDetails],
  );

  const { squareRef, desiredCardWidth, columns, gutterWidth } =
    useCardLayoutWithImpressions();

  if (error) {
    return <ErrorDisplay />;
  }

  if (isLoading || isRefetching) {
    return (
      <div
        className={'flex justify-center items-center w-full h-screen m-auto'}
      >
        <Loader />
      </div>
    );
  }

  const OrderFilterRow = ({ className }: { className: string }) => (
    <div className={className}>
      <Text
        className="text-thememutedforeground w-fit text-nowrap"
        size={'base'}
        weight={'normal'}
      >
        {(collectionDetails?.pages ?? []).length > 0
          ? (collectionDetails?.pages[0].TotalRecords ?? 0)
          : 0}
        {((collectionDetails?.pages ?? []).length > 0
          ? (collectionDetails?.pages[0].TotalRecords ?? 0)
          : 0) > 1
          ? ' Ads'
          : ' Ad'}
      </Text>

      <OrderFilterSelect
        defaultFilter={orderFilter || 'Random'}
        options={['Random', 'Recent', 'Popular', 'Oldest']}
      />
    </div>
  );

  return (
    <Stack className="gap-3 lg:gap-8">
      <Stack className="gap-3 lg:gap-2">
        <Breadcrumb>
          <BreadcrumbList>
            <BreadcrumbItem>
              <BreadcrumbLink asChild>
                <Link
                  to={
                    !(permissionData?.userCanAccessEverything ?? false)
                      ? '/feeds/collections/creativeos'
                      : '/feeds/collections'
                  }
                >
                  Collections
                </Link>
              </BreadcrumbLink>
            </BreadcrumbItem>
            <BreadcrumbSeparator />
            <BreadcrumbItem>
              <BreadcrumbPage>Expert</BreadcrumbPage>
            </BreadcrumbItem>
          </BreadcrumbList>
        </Breadcrumb>
        <div className={'flex justify-between gap-6 items-center'}>
          <div className="flex gap-2 items-center">
            <img
              className={'rounded-full w-9 h-9 bg-themeaccent'}
              src={'/bg_gradient.jpg'}
              alt={
                (collectionDetails?.pages ?? []).length > 0
                  ? collectionDetails?.pages[0].collection.Title
                  : ''
              }
            />
            <Text weight="semibold" size={'xxl'}>
              {(collectionDetails?.pages ?? []).length > 0
                ? collectionDetails?.pages[0].collection.Title
                : ''}
            </Text>
          </div>
          <Button
            onClick={() => {
              copyToClipboard(
                `${window.location.origin}/feeds/collections/${collectionID}`,
              );
              showToastNotification('success', { message: 'Copied!' });
            }}
            className={'flex'}
            variant={'outline'}
            size={'iconSm'}
          >
            <LinkIcon />
          </Button>
        </div>
        <div className={`flex gap-6 items-center justify-between`}>
          <div className={`flex gap-1 items-center`}>
            <Text
              className="text-thememutedforeground w-fit text-nowrap"
              size={'base'}
              weight={'normal'}
            >
              Templates Created by
            </Text>
            <Text
              className="text-themeforeground w-fit text-nowrap"
              size={'base'}
              weight={'normal'}
            >
              Creative OS
            </Text>
          </div>
          <OrderFilterRow className="items-center gap-3 hidden lg:flex w-fit" />
        </div>
        <OrderFilterRow className="items-center gap-3 lg:hidden flex w-fit" />
      </Stack>
      <div ref={squareRef} className={'relative w-full pb-10'}>
        {collectionDetails && (
          <>
            {((collectionDetails?.pages ?? []).length > 0
              ? collectionDetails.pages[0].TotalRecords
              : 0) === 0 ? (
              <div className={'lg:h-96 flex justify-center items-center'}>
                <p>No templates added to this collection</p>
              </div>
            ) : (
              allData && (
                <div>
                  <TypedResponsiveMasonry
                    columnsCountBreakPoints={columns ? { 0: columns } : {}} // Columns is determined by the width of the container
                  >
                    <MasonryComponent gutter={gutterWidth / 16 + 'rem'}>
                      {allData.map((ad) => {
                        return (
                          <AdCard
                            key={ad.atID}
                            adData={{
                              Ad: ad,
                              IsLocked: false,
                              IsPublic: false,
                              brandName: undefined,
                              shouldInvalidateCache: false,
                              DesiredWidth: desiredCardWidth,
                            }}
                          />
                        );
                      })}
                    </MasonryComponent>
                  </TypedResponsiveMasonry>
                </div>
              )
            )}
          </>
        )}
        <div className={'relative'}>
          <div
            className={
              'absolute w-[10px] h-[1500px] transform translate-y-[-1500px]' // Having the height be 1500px helps when the masonry grid has one column longer than another
            }
            ref={scrollRef}
          ></div>
        </div>
        <div className={'relative'}>
          <div
            className={
              'absolute w-[10px] h-[1500px] transform translate-y-[-1500px]' // Having the height be 1500px helps when the masonry grid has one column longer than another
            }
            ref={scrollRef}
          ></div>
        </div>
      </div>
    </Stack>
  );
}
