import { Button,  } from "@/components/shadcn/button.tsx";
import {
  Desktop,
  Download,
  Link as LinkIcon,
  Mobile,
} from "@mynaui/icons-react";
import { Link } from "@tanstack/react-router";

import {
  Tabs,
  TabsContent,
  TabsList,
  TabsTrigger,
} from "@/components/shadcn/tabs.tsx";
import {
  LandingFeedProps,
  ManuallySaveFeedAdToBoard,
} from "@/components/templates/LandingAdCard";
import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from "@/components/ui/tooltip.tsx";
import { useCopyToClipboard } from "@/hooks/useCopyToClipboard.tsx";
import showToastNotification from "@/hooks/useShowToast.tsx";

export const LanderInspirationDetailsMedia = ({
  data,
  saveOpen,
  setSaveOpen,
  IsPublic,
  numberOfActiveAdsForBrand
}: {
  data: LandingFeedProps;
  saveOpen: boolean;
  IsPublic: boolean;
  setSaveOpen: (x: boolean) => void;
  numberOfActiveAdsForBrand:
  | {
      numberOfActiveAds: number;
    }
  | undefined;
}) => {
  const [, copyToClipboard] = useCopyToClipboard();

  return (
    <div className="p-3 lg:p-4 w-full flex flex-col items-center">
      <div className={"flex gap-5 justify-between w-full"}>
        <div className={`flex gap-1 items-center`}>
          {data.brandImage && data.brandId && (
            <Link
              to={`/feeds/brands/$brandID`}
              params={{ brandID: data.brandId }}
            >
              <img
                src={data.brandImage}
                alt={data.brandName || "Brand name"}
                className={`bg-[#BCBCBC] rounded-full w-8 h-8 object-contain`}
              ></img>
            </Link>
          )}
          <div>
            {data.brandName && data.brandId && (
              <Link
                to={`/feeds/brands/$brandID`}
                params={{ brandID: data.brandId }}
              >
                <h4 className={`truncate font-[500] text-sm`}>
                  {data.brandName}
                </h4>
              </Link>
            )}
             <p className={"text-xs text-thememutedforeground"}>
              Currently running{" "}
              {numberOfActiveAdsForBrand?.numberOfActiveAds || 0} ads
            </p>
          </div>
        </div>

        <div className={"flex gap-2"}>
          {!IsPublic && data && (
            <ManuallySaveFeedAdToBoard
              open={saveOpen}
              onOpenChange={setSaveOpen}
              data={data}
              variant={"outline"}
            />
          )}

          {(data.desktopScreenshot ?? data.mobileScreenshot) && (
            <Tooltip>
              <TooltipTrigger asChild>
                <a
                  href={data.desktopScreenshot ?? data.mobileScreenshot ?? ""}
                  download={"image.png"}
                >
                  <Button variant={"outline"} size={"icon"}>
                    <Download className="w-5 h-5" />
                  </Button>
                </a>
              </TooltipTrigger>
              <TooltipContent>
                <p>Download as image</p>
              </TooltipContent>
            </Tooltip>
          )}

          <Tooltip>
            <TooltipTrigger>
              <Button
                onClick={() => {
                  copyToClipboard(
                    `${window.location.origin}/share/inspiration/landing-pages/${data.landerId}`
                  );
                  showToastNotification("success", {
                    message: "Copied!",
                  });
                }}
                variant={"outline"}
                size={"icon"}
              >
                <LinkIcon />
              </Button>
            </TooltipTrigger>
            <TooltipContent>
              <p>Share a public link</p>
            </TooltipContent>
          </Tooltip>
        </div>
      </div>
      <div
        className={
          "w-fit border border-themeborder bg-white rounded my-4"
        }
      >
        <div>
          <div className={"p-3"}>
            <div className={"flex gap-5 justify-between"}>
              <div>
                {data.brandName && data.brandId && (
                  <Link
                    to={`/feeds/brands/$brandID`}
                    params={{ brandID: data.brandId }}
                  >
                    <h4 className={`truncate font-[500] text-sm`}>
                      {data.brandName}
                    </h4>
                  </Link>
                )}
              </div>
            </div>
          </div>
        </div>
        <div>
         
          <Tabs defaultValue={"desktop"}>
            <div className={"flex justify-center"}>
              <TabsList>
                <TabsTrigger
                  value={"desktop"}
                  className={"flex gap-1.5 items-center"}
                >
                  <Desktop className={"w-4 h-4"} /> <span>Desktop</span>
                </TabsTrigger>
                <TabsTrigger
                  value={"mobile"}
                  className={"flex gap-1.5 items-center"}
                >
                  <Mobile className={"w-4 h-4"} /> <span>Mobile</span>
                </TabsTrigger>
              </TabsList>
            </div>
            <TabsContent value={"desktop"}>
              {data.desktopScreenshot && (
                <img
                  alt={"screenshot of a landing page"}
                  loading={"lazy"}
                  className={"w-full rounded-b-lg"}
                  src={data.desktopScreenshot}
                />
              )}
            </TabsContent>
            <TabsContent value={"mobile"}>
              {data.mobileScreenshot && (
                <img
                  alt={"screenshot of a landing page"}
                  loading={"lazy"}
                  className={"w-full rounded-b-lg lg:max-w-[23.25rem]"}
                  src={data.mobileScreenshot}
                />
              )}
            </TabsContent>
          </Tabs>
        </div>
      </div>
    </div>
  );
};
