import { trpc } from "@/App.tsx";
import FreeTrialEnding from "@/components/FreeTrialEnding.tsx";
import {
  MasonryComponent,
  TypedResponsiveMasonry,
} from "@/components/ResponsiveMasonryWrapper.tsx";
import { Stack, Text } from "@/components/custom-components";
import { Loader } from "@/components/custom-components/Loader/index.tsx";
import { ErrorDisplay } from "@/components/error.tsx";
import { Button } from "@/components/shadcn/button.tsx";
import { AdCard } from "@/components/templates/AdCard/index.tsx";
import { StartFreeTrialAdCard } from "@/components/templates/AdGridView";
import useCardLayoutWithImpressions from "@/hooks/useCardLayoutWithImpressions.tsx";
import { PRO_PLAN } from "@/utils/data/plans.ts";
import { createFileRoute, useNavigate } from "@tanstack/react-router";
import { useState } from "react";
import { Tags } from "../../../../src/shared/airtable";

type SearchParams = {
  Tags?: Tags;
};

export const Route = createFileRoute("/share/templates/$adID")({
  component: SharedAd,
  validateSearch: (search: Record<string, unknown>): SearchParams => {
    const tag = search?.Tags as Tags | undefined;
    return {
      Tags: tag,
    };
  },
});

function SharedAd() {
  const navigate = useNavigate({ from: "/share/templates/$adID" });
  const { adID } = Route.useParams();
  const { cacheBuster } = Route.useSearch();
  const [upgradeDialogOpen, setUpgradeDialogOpen] = useState<boolean>(false);
  const { squareRef, columns, gutterWidth, desiredCardWidth } =
    useCardLayoutWithImpressions();

  const { data: permissionData } = trpc.permissions.useQuery(null, {});
  const { data: authUser, isLoading: isLoadingMe } = trpc.me.useQuery(null);
  const {
    data: adTemplates,
    isError,
    isLoading,
  } = trpc.publicPostByID.useQuery({
    adID,
    cacheBuster: cacheBuster,
  });
  if (!cacheBuster) {
    navigate({
      search: (old) => {
        return {
          ...old,
          cacheBuster: Math.random(),
        };
      },
      params: (old) => {
        return {
          ...old,
        };
      },
    });
  }

  return (
    <div className={"relative h-[97vh]"}>
      {authUser && upgradeDialogOpen && permissionData && (
        <FreeTrialEnding
          open={upgradeDialogOpen}
          onOpenChange={() => setUpgradeDialogOpen(false)}
          permissionData={permissionData}
        />
      )}
      <Stack className="!mb-6 gap-8">
        <Stack className="gap-3">
          <div
            className={
              "flex justify-between lg:justify-start gap-5 items-center"
            }
          >
            <Text weight="semibold" size={"xxl"}>
              Ad Templates
            </Text>
            {!authUser ? (
              <div className="flex gap-2 items-center">
                <a target={"_blank"} href={"https://app.creativeos.io/login"}>
                  <Button size={"sm"} variant={"ghost"} className="flex">
                    Log In
                  </Button>
                </a>

                <a target={"_blank"} href={"https://www.creativeos.io/#sign"}>
                  <Button
                    size={"sm"}
                    variant={"destructivePurple"}
                    className="flex"
                  >
                    Get Started
                  </Button>
                </a>
              </div>
            ) : authUser &&
              !(
                permissionData?.usersPlans.includes(PRO_PLAN.plan_id) ||
                permissionData?.usersPlans.includes(PRO_PLAN.no_trial_plan_id)
              ) ? (
              <Button
                onClick={() => setUpgradeDialogOpen(true)}
                variant={"destructivePurple"}
                size={"sm"}
              >
                Upgrade
              </Button>
            ) : (
              <></>
            )}
          </div>
          <Text
            className="text-thememutedforeground w-fit"
            size={"base"}
            weight={"normal"}
          >
            Somebody shared an Ad template with you!
            {!authUser
              ? ` Sign up for Creative OS to access our full library of Ad, Email, and Landing Page Templates, and so much more. `
              : !(
                    permissionData?.usersPlans.includes(PRO_PLAN.plan_id) ||
                    permissionData?.usersPlans.includes(
                      PRO_PLAN.no_trial_plan_id,
                    )
                  )
                ? " Upgrade to Creative OS Pro to access our full library of Email and Landing Page Templates."
                : ""}
            {!authUser && (
              <a
                className="text-black underline"
                target={"_blank"}
                href="https://www.creativeos.io/"
              >
                Learn more about Creative OS.
              </a>
            )}
          </Text>
        </Stack>

        {isLoading || isLoadingMe ? (
          <div
            className={
              "flex justify-center items-center w-full h-screen m-auto"
            }
          >
            <Loader />
          </div>
        ) : isError ? (
          <ErrorDisplay />
        ) : (
          adTemplates && (
            <div ref={squareRef} className={"relative w-full pb-10"}>
              <div>
                <TypedResponsiveMasonry
                  columnsCountBreakPoints={columns ? { 0: columns } : {}} // Columns is determined by the width of the container
                >
                  <MasonryComponent gutter={gutterWidth / 16 + "rem"}>
                    {[
                      adTemplates[0] && (
                        <AdCard
                          adData={{
                            IsPublic: !authUser,
                            IsLocked: !authUser,
                            IsShared: true,
                            DesiredWidth: desiredCardWidth,
                            shouldInvalidateCache: false,
                            brandName: undefined,
                            avatar: undefined,
                            Ad: adTemplates[0],
                          }}
                          openDetailsOnInit={authUser ? true : false}
                          key={adTemplates[0].atID}
                        />
                      ),
                      ...(!authUser
                        ? [
                            <StartFreeTrialAdCard
                              key="startFreeTrial"
                              fullWidthButton
                              titleText={
                                !authUser ? undefined : "Upgrade to Unlock"
                              }
                              bodyText={
                                !authUser
                                  ? undefined
                                  : "Level up your ad workflow with Creative OS Pro"
                              }
                              buttonText={
                                !authUser ? "Start Free Trial" : "Upgrade Now"
                              }
                              href={
                                !authUser
                                  ? "https://www.creativeos.io/#sign"
                                  : undefined
                              }
                              onClick={
                                authUser
                                  ? () => setUpgradeDialogOpen(true)
                                  : undefined
                              }
                            />,
                          ]
                        : []),
                      ...adTemplates.slice(1).map((adTemplate) => (
                        <AdCard
                          adData={{
                            IsPublic: !authUser,
                            IsLocked: !authUser,
                            DesiredWidth: desiredCardWidth,
                            shouldInvalidateCache: false,
                            brandName: undefined,
                            avatar: undefined,
                            Ad: adTemplate,
                          }}
                          key={adTemplate.atID}
                        />
                      )),
                    ]}
                  </MasonryComponent>
                </TypedResponsiveMasonry>
              </div>
            </div>
          )
        )}
      </Stack>
    </div>
  );
}
