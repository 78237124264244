import { Button } from '@/components/shadcn/button';
import {
  DialogHeader,
  DialogTitle,
} from '@/components/shadcn/dialog';
import { Input } from '@/components/shadcn/input.tsx';
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormMessage,
} from '@/components/ui/form.tsx';
import showToastNotification from '@/hooks/useShowToast';
import { WorkOSUser } from '@/routes/login.tsx';
import { trpc } from '@/utils/trpc';
import { zodResolver } from '@hookform/resolvers/zod';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { z } from 'zod';


export function UserProfileEdit({
    userData,
    setEdit,
  }: {
    userData: WorkOSUser;
    setEdit: (status: boolean) => void;
  }) {
    const utils = trpc.useUtils();
  
    const { mutate: editUserProfile, isPending: editIsPending } =
      trpc.updateUserProfile.useMutation({
        onSuccess: async () => {
          showToastNotification('success', {
            message: 'Profile details successfully updated!',
          });
  
          await utils.me
            .invalidate(undefined, { refetchType: 'all' })
            .then(() => {
              setEdit(false);
            });
  
          if (typeof localStorage !== 'undefined') {
            const userDataStringified = localStorage.getItem('_wos_user');
            const userData: {
              object: 'user';
              email: string;
              id: string;
              createdAt: string;
              updatedAt: string;
              firstName: string | null;
              lastName: string | null;
              emailVerified: boolean;
              profilePictureUrl: string | null;
            } = userDataStringified ? JSON.parse(userDataStringified) : {};
            localStorage.setItem(
              '_wos_user',
              JSON.stringify({
                ...userData,
                firstName: form.getValues('firstName'),
                lastName: form.getValues('lastName'),
              }),
            );
          }
        },
      });
    const formSchema = z.object({
      firstName: z.string().min(1, 'First name is required'),
      lastName: z.string().min(1, 'Last name is required'),
      email: z.string().optional(),
    });
  
    const form = useForm<z.infer<typeof formSchema>>({
      resolver: zodResolver(formSchema),
      defaultValues: {
        firstName: '',
        lastName: '',
        email: '',
      },
    });
  
    const handleSubmitEditProfile = async (
      values: z.infer<typeof formSchema>,
    ) => {
      try {
        if (userData) {
          const { firstName, lastName } = values;
          editUserProfile({ data: { firstName, lastName }, userId: userData.id });
        }
      } catch (e) {
        console.log(e);
      }
    };
  
    useEffect(() => {
      if (userData) {
        form.reset({
          firstName: userData.firstName || '',
          lastName: userData.lastName || '',
          email: userData.email || '',
        });
      }
    }, [userData, form]);
  
    return (
      <div className={`flex flex-col gap-6`}>
        <DialogHeader>
          <DialogTitle className="">
            <div className="flex justify-between mt-10">
              <div className={'flex justify-start items-center gap-1.5  w-fit '}>
                <div className={'flex w-9 h-9'}>
                  <img
                    alt={''}
                    src={userData?.avatar ?? '/bg_gradient.jpg'}
                    className={
                      'rounded-full cursor-pointer shrink-0 bg-[#A259FF] w-9 h-9'
                    }
                  />
                </div>
                <span
                  className={
                    'max-w-[15rem] text-themeforeground h-full font-semibold text-2xl leading-8 truncate overflow-ellipsis overflow-hidden'
                  }
                >
                  {`${
                    userData?.firstName || userData?.lastName
                      ? `${userData.firstName ?? ''} ${userData.lastName ?? ''}`
                      : ''
                  }`.trim()}
                </span>
              </div>
            </div>
          </DialogTitle>
        </DialogHeader>
        <div className="gap-3 flex flex-col max-w-lg">
          <div className={'flex justify-between items-center gap-1.5 h-9'}>
            <span className="font-semibold text-base leading-6 text-themeforeground">
              Account
            </span>
          </div>
          <Form {...form}>
            <form
              onSubmit={form.handleSubmit(handleSubmitEditProfile)}
              className={''}
            >
              <div className={'flex flex-col gap-3'}>
                <FormField
                  control={form.control}
                  name="firstName"
                  render={({ field }) => (
                    <FormItem>
                      <FormControl>
                        <Input placeholder={'First Name'} {...field} />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                <FormField
                  control={form.control}
                  name="lastName"
                  render={({ field }) => (
                    <FormItem>
                      <FormControl>
                        <Input placeholder={'Last Name'} {...field} />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                <FormField
                  control={form.control}
                  name="email"
                  disabled
                  render={({ field }) => (
                    <FormItem>
                      <FormControl>
                        <Input
                          className="shadow-none"
                          placeholder={'Email'}
                          {...field}
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                <div className={'flex justify-start gap-2'}>
                  <Button
                    type={'submit'}
                    className={'w-15 px-3 h-8 text-sm'}
                    variant={'default'}
                    disabled={editIsPending}
                    loading={editIsPending}
                  >
                    {editIsPending ? 'Saving...' : 'Save Changes'}
                  </Button>
                </div>
              </div>
            </form>
          </Form>
        </div>
      </div>
    );
  }
  