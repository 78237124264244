import { UserProfileDialog } from "@/components/UserProfileDialog";
import { Text } from "@/components/custom-components";
import { Button as ShadButton } from "@/components/ui/button.tsx";
import { trpc } from "@/utils/trpc.ts";
import { useState } from "react";

export const ManageAccount = ({
  sidebarCollapsed = false,
}: {
  sidebarCollapsed?: boolean;
}) => {
  const [modalOpen, setModalOpen] = useState(false);

  return (
    <div className={"flex justify-start items-center gap-[1rem]"}>
      <div className={"flex w-[2.5rem] h-[2.5rem]"}>
        <img
          alt={""}
          onClick={() => {
            setModalOpen(true);
          }}
          src={"/bg_gradient.jpg"}
          className={
            "rounded-full cursor-pointer shrink-0 bg-[#A259FF] w-[2.5rem] h-[2.5rem]"
          }
        />
      </div>
      {!sidebarCollapsed && (
        <div className={"hidden md:block md:ml-2"}>
          <ShadButton
            className={"p-0 h-auto text-black font-medium"}
            variant={"link"}
            onClick={() => {
              setModalOpen(true);
            }}
          >
            <span className={"text-black"}>My Account</span>
          </ShadButton>
          {modalOpen && (
            <UserProfileDialog
              open={modalOpen}
              onOpenChange={() => setModalOpen(false)}
            />
          )}
        </div>
      )}
    </div>
  );
};

/**
 * Using ManageAccountV2 here instead of replacing the old manage account
 * Reason: The ManageAccount component is being used in the AppHeader component, which I am currently not modifying
 * and is not related to the sidebar.
 * This will be revisited when the AppHeader is being redesigned
 */
export const ManageAccountV2 = ({
  sidebarCollapsed = false,
  showName = true,
}: {
  sidebarCollapsed?: boolean;
  showModal?: boolean;
  showName?: boolean;
}) => {
  const [modalOpen, setModalOpen] = useState( false);
  const { data: userData, isLoading: fetchingUserData } = trpc.me.useQuery(
    null,
    { refetchOnWindowFocus: false }
  );

  return (
    <>
      {( modalOpen) && (
        <UserProfileDialog
          open={modalOpen}
          onOpenChange={() => {
            setModalOpen(false);
          }}
        />
      )}
      <div
        className={
          "flex justify-start items-center gap-1.5 cursor-pointer w-full"
        }
        onClick={() => {
          setModalOpen(true);
        }}
      >
        {userData ? (
          <div className={"flex w-6 h-6"}>
              <img
                alt={""}
                src={userData?.avatar ?? "/bg_gradient.jpg"}
                className={
                  "rounded-full cursor-pointer shrink-0 bg-thememuted w-6 h-6"
                }
              />
          </div>
        ) : (
          <img
            alt={"Creative OS"}
            src={"/images/cos-logo-nobg.png"}
            className={"h-6 hidden lg:flex"}
          />
        )}
        {!sidebarCollapsed && !fetchingUserData && userData && showName && (
          <Text
            size="xs"
            weight={"medium"}
            className={
              "w-[8.625rem] h-full truncate overflow-ellipsis overflow-hidden"
            }
          >
            {`${
              userData?.firstName || userData?.lastName
                ? `${userData.firstName ?? ""} ${userData.lastName ?? ""}`
                : userData?.email
            }`.trim()}
          </Text>
        )}
      </div>
    </>
  );
};
